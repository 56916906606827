import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/Modules/';

class HostModuleService {
  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new HostModuleService();
