














































































import Vue from 'vue';
import HostModuleService from '@/services/owner/HostModuleService';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import Jumbotron from '@/components/shared/Jumbotron.vue';
import { MetaInfo } from 'vue-meta';

class ModuleDto {
  endedAt!: Date | string;
  name!: String;
  isActive!: boolean;
  status!: String;
}

export default Vue.extend({
  components: { FormatedTimestamp, DataTable, Jumbotron },
  data() {
    return {
      data: {} as {
        modules: { items: ModuleDto[] };
      },
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'id', asc: true },
      currentPage: 1,
      loading: false,
      ready: false,
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
    this.loadData();
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostModulesIndex.meta.title').toString(),
    };
  },
  methods: {
    moduleLinkName(name: string): string {
      switch (name) {
        case 'TelegramService':
          return 'HostTelegramModule';
        default:
          return '';
      }
    },
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostModulesIndex.breadcrumb.last' },
      ]);
    },

    loadData() {
      this.loading = true;
      HostModuleService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.ready = true;
        this.loading = false;
        this.data = res.value;
        this.data.modules.items = res.value.modules.items.map((t: ModuleDto) => ({
          ...t,
          endedAt: t.endedAt != '' ? new Date(t.endedAt) : '',
        }));
        this.pages = res.value.modules.totalPages;
      });
    },
  },
});
